"use client";

import { useState, useCallback } from "react";

import useHasMounted from "./useHasMounted";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

const useWindowWidth = () => {
  const hasMounted = useHasMounted();
  const [width, setWidth] = useState(0);

  const handleResize = useCallback(() => {
    if (!hasMounted) return;
    setWidth(window.innerWidth);
  }, [hasMounted]);

  useIsomorphicLayoutEffect(() => {
    if (hasMounted) {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasMounted, handleResize]);

  return width;
};

export default useWindowWidth;
